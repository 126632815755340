import React from 'react'
import { ArticleContentBlock } from './ArticleContentBlock'

export const Introduction = (): JSX.Element => (
  <ArticleContentBlock
    header="Welcome"
    text="At Raven Code we will help you every step of the way to ensure that
          your software project is delivered to the highest standard and meets
          your business needs. Our tech expertise has been trusted by businesses
          large and small."
  />
)
