import React from 'react'
import styled from 'styled-components'
import { ArticleContentBlock } from './ArticleContentBlock'

export const Team = (): JSX.Element => (
  <ArticleContentBlock
    id="team"
    header="Team"
    title="Meet the team"
    text="We have over 35 years of development experience in delivering
          performant and secure high quality software products. We have worked
          with businesses both large and small delivering everything from
          microsites to full blown web applications, so you can be sure that
          your project is in safe hands."
  >
    <TeamTitle>Sam Sheppard (Co-founder / Developer)</TeamTitle>
    <p>
      Sam is a senior fullstack software engineer with a drive to keep up to
      date on current trends and best practices. With over 14 years of
      experience, he has a history of producing secure, performant and scalable
      systems in .Net &amp; Javascript. Having worked with PureGym, Landmarc, L3
      Commercial Aviation, Fitness First and many more he strives to take a
      client&apos;s vision to generate a set of achievable requirements and
      provide quality and maintainable solutions.
    </p>
    <TeamTitle>Carl Saunders (Co-founder / Developer)</TeamTitle>
    <p>
      Carl has been developing software for over 20 years, specialising in
      front-end development, especially using JavaScript. He&apos;s a developer
      with a passion for following best practices and good standards, combining
      this with the discipline and ability to deliver software solutions to the
      highest standard.
    </p>
    <p>
      He&apos;s worked for multiple clients, small to large including Merrill
      Lynch, Carlsberg, News International, Fitness First and Hitachi Data
      Systems, so understands the importance of software in a business context.
      He’s able to see the business needs and write functional code that is easy
      to maintain.
    </p>
  </ArticleContentBlock>
)

export const TeamTitle = styled.h3`
  margin-bottom: 1.5em;
`
