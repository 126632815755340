import React from 'react'
import { Row } from 'styled-bootstrap-grid'
import CodeSvg from '../assets/images/services/code.svg'
import MicrositesSvg from '../assets/images/services/cogs.svg'
import ConsultingSvg from '../assets/images/services/communication.svg'
import DevopsSvg from '../assets/images/services/lightning.svg'
import MobileSvg from '../assets/images/services/mobile.svg'
import PhoneSvg from '../assets/images/services/telephone.svg'
import TestingSvg from '../assets/images/services/tick.svg'
import WebSvg from '../assets/images/services/webapp.svg'
import { ArticleContentBlock } from './ArticleContentBlock'
import { Service, ServiceProps } from './Service'

export const Services = (): JSX.Element => {
  const services: ServiceProps[] = [
    {
      title: 'Code Audits/Reviews',
      image: CodeSvg,
      text:
        'We can audit your source code to identify any security issues, discover bugs and highlight potential areas for improvement, particularly with regard to best practices.',
    },
    {
      title: 'Support',
      image: PhoneSvg,
      text:
        'We understand that once your product is live, you want to know its being looked after, with monitoring and various support plans, we can give you piece of mind.',
    },
    {
      title: 'Web Apps / Websites',
      image: WebSvg,
      text:
        'Whether you have a new or existing website or web based application, we can provide a technical solution that meets your needs.',
    },
    {
      title: 'Consulting',
      image: ConsultingSvg,
      text:
        'If you have any questions, concerns or a need for technical expertise, we can provide guidance and help to ensure your project succeeds.',
    },
    {
      title: 'Testing',
      image: TestingSvg,
      text:
        'Whether you require manual, automated, unit or any other types of testing, we can assist following your preferred approach for example, <abbr title="Test Driven Development">TDD</abbr> or <abbr title="Behavioural Driven Development">BDD</abbr>.',
    },
    {
      title: 'Mobile Apps',
      image: MobileSvg,
      text:
        'Perhaps you require a native mobile application or would prefer a progressive web application, we can help make that a reality.',
    },
    {
      title: 'Devops',
      image: DevopsSvg,
      text:
        'Given the vast amount of hosting options available we can provide our expertise to meet your technology requirements and deliver a solution using <abbr title="Infrastructure as code">IaC</abbr> for consistent configuration.',
    },
    {
      title: 'Micro-sites',
      image: MicrositesSvg,
      text:
        'A micro site can help market a specific area of your business, whether to highlight a specific campaign or target a particular demographic.',
    },
  ]

  return (
    <ArticleContentBlock
      id="services"
      header="Services"
      title="How can we help you?"
      text="Whether you have a project that needs extra software development
      assistance, or you have website and mobile app designs but you don’t
      know where to turn next? We will be happy to help. With our range of
      services we can take your business goals and provide a solution that
      meets your needs."
    >
      <Row>
        {services.map(({ title, image, text }: ServiceProps) => (
          <Service
            key={`service-${title}`}
            title={title}
            image={image}
            text={text}
          />
        ))}
      </Row>
    </ArticleContentBlock>
  )
}
