import React from 'react'
import { Col, Row, Container } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import media from 'styled-media-query'

export const HeroTitle = (): JSX.Element => {
  const StyledContainer = styled(Container)`
    height: 67vw;
    max-height: calc(100vh - 136px);
  `
  const StyledRow = styled(Row)`
    height: 100%;
  `

  const Title = styled.h1`
    font-size: 7em;
    ${media.lessThan('large')`
      font-size: 4.5rem;
  `}
    ${media.lessThan('medium')`
      font-size: 3rem;
  `}
    ${media.lessThan('small')`
      font-size: 2rem;
    `}
  `

  const SubTitle = styled.h2`
    font-size: 5em;
    ${media.lessThan('large')`
      font-size: 3rem;
    `}
    ${media.lessThan('medium')`
      font-size: 2rem;
    `}
    ${media.lessThan('small')`
      font-size: 1.6rem;
    `}
  `
  const TitleHighlight = styled.span`
    border-bottom: 1px solid ${({ theme }) => theme.colors.secondary};
    padding-bottom: 0.2em;
  `

  return (
    <StyledContainer>
      <StyledRow alignItems="center">
        <Col xs={12}>
          <SubTitle>We are</SubTitle>
          <Title>
            <TitleHighlight>Raven Code.</TitleHighlight>
          </Title>
        </Col>
      </StyledRow>
    </StyledContainer>
  )
}
