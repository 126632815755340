import React from 'react'
import { Hero } from '../components/Hero'
import { HeroTitle } from '../components/HeroTitle'
import { Impression } from '../components/Impression'
import { Introduction } from '../components/Introduction'
import { Layout } from '../components/Layout'
import SEO from '../components/SEO'
import { Services } from '../components/Services'
import { Team } from '../components/Team'
import { Technologies } from '../components/Technologies'

const IndexPage = (): JSX.Element => (
  <Layout>
    <Hero />
    <SEO description="A software development agency with trusted expertise that brings your web, mobile and software projects to life." />
    <HeroTitle />
    <Impression
      text="A software development agency with trusted expertise that brings your
        web, mobile and software projects to life"
    />
    <Introduction />
    <Services />
    <Technologies />
    <Team />
  </Layout>
)

export default IndexPage
