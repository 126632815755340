import React from 'react'
import { Col } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import media from 'styled-media-query'

export interface ServiceProps {
  image: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  title: string
  text: string
}

export const Service = ({ title, image, text }: ServiceProps): JSX.Element => {
  const ServiceImage = image

  return (
    <Col col={12} md={6} xl={4}>
      <ServiceImageContainer>
        <ServiceImage />
      </ServiceImageContainer>
      <ServiceContentContainer>
        <h3>{title}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        ></p>
      </ServiceContentContainer>
    </Col>
  )
}

const ServiceImageContainer = styled.div`
  svg {
    object-fit: cover;
    max-height: 500px;
    width: 100%;
    height: auto;
  }
`

const ServiceContentContainer = styled.div`
  margin-top: 2rem;
  margin-left: 2rem;
  ${media.lessThan('medium')`
  margin-left: 1.6rem;
`}
`
