import React from 'react'
import { Row } from 'styled-bootstrap-grid'
import AmazonSvg from '../assets/images/technologies/amazon.svg'
import AngularSvg from '../assets/images/technologies/angular.svg'
import AzureSvg from '../assets/images/technologies/azure.svg'
import DotNetSvg from '../assets/images/technologies/dotNET.svg'
import GatsbySvg from '../assets/images/technologies/gatsby.svg'
import GraphQlSvg from '../assets/images/technologies/graphql.svg'
import JavascriptSvg from '../assets/images/technologies/javascript.svg'
import JestSvg from '../assets/images/technologies/jest.svg'
import MongoSvg from '../assets/images/technologies/mongodb.svg'
import NetlifySvg from '../assets/images/technologies/netlify.svg'
import NodeSvg from '../assets/images/technologies/nodejs.svg'
import ReactSvg from '../assets/images/technologies/reactjs.svg'
import ReduxSvg from '../assets/images/technologies/redux.svg'
import RestSvg from '../assets/images/technologies/rest.svg'
import SqlSvg from '../assets/images/technologies/sqlServer.svg'
import TypescriptSvg from '../assets/images/technologies/typescript.svg'
import UmbracoSvg from '../assets/images/technologies/umbraco.svg'
import { ArticleContentBlock } from './ArticleContentBlock'
import FullContainer from './FullContainer'
import { Technology, TechnologyProps } from './Technology'

export const Technologies = (): JSX.Element => {
  const technologies: TechnologyProps[] = [
    {
      text: '.Net Framework',
      image: DotNetSvg,
    },
    {
      text: 'Node Js',
      image: NodeSvg,
    },
    {
      text: 'REST API',
      image: RestSvg,
    },
    {
      text: 'Netlify',
      image: NetlifySvg,
    },
    {
      text: 'Umbraco',
      image: UmbracoSvg,
    },
    {
      text: 'SQL Server',
      image: SqlSvg,
    },
    {
      text: 'Mongo DB',
      image: MongoSvg,
    },
    {
      text: 'Graph QL',
      image: GraphQlSvg,
    },
    {
      text: 'Javascript',
      image: JavascriptSvg,
    },
    {
      text: 'Typescript',
      image: TypescriptSvg,
    },
    {
      text: 'React',
      image: ReactSvg,
    },
    {
      text: 'React Native',
      image: ReactSvg,
    },
    {
      text: 'Angular',
      image: AngularSvg,
    },
    {
      text: 'Jest',
      image: JestSvg,
    },
    {
      text: 'Redux',
      image: ReduxSvg,
    },
    {
      text: 'Gatsby',
      image: GatsbySvg,
    },
    {
      text: 'Azure',
      image: AzureSvg,
    },
    {
      text: 'Amazon',
      image: AmazonSvg,
    },
  ]

  return (
    <FullContainer>
      <ArticleContentBlock
        id="technologies"
        header="Technologies"
        title="Technologies Used"
        text="Our team is constantly ensuring they are up to date with the
              latest technologies. Below are some of the technologies we’ve
              specialised in over the years. If your preferred technology is not
              listed, just get in touch to see if we can meet your requirements."
        lightTheme
      >
        <Row>
          {technologies.map(({ image, text }: TechnologyProps) => (
            <Technology key={`service-${text}`} image={image} text={text} />
          ))}
        </Row>
      </ArticleContentBlock>
    </FullContainer>
  )
}
