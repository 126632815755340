import React from 'react'
import { Col } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export interface TechnologyProps {
  image: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  text: string
}

export const Technology = ({ image, text }: TechnologyProps): JSX.Element => {
  const TechnologyImage = image

  return (
    <Col col={12} sm={6} md={4} lg={3} xl={2}>
      <TechnologyContainer>
        <TechnologyImageContainer>
          <TechnologyImage />
        </TechnologyImageContainer>
        <TechnologyContentContainer>
          <TechnologyTitle
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </TechnologyContentContainer>
      </TechnologyContainer>
    </Col>
  )
}

const TechnologyImageContainer = styled.div`
  width: 100%;
  svg {
    object-fit: cover;
    max-height: 55px;
    width: 100%;
    height: auto;
  }
`

const TechnologyTitle = styled.h3`
  font-weight: 300;
  font-size: 0.9em;
`

const TechnologyContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const TechnologyContentContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5em;
  width 100%;
`
