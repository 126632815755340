import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import Img from 'gatsby-image'

export const Hero = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query HeroImageQuery {
      heroImage: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <ImageContainer>
        <Image
          title="Hero image"
          alt="Code screen"
          fluid={data.heroImage.childImageSharp.fluid}
        />
      </ImageContainer>
    </>
  )
}

const ImageContainer = styled.div`
  position: fixed;
  z-index: -1;
  background-color: #000000;
  margin-top: -136px;
  padding-top: 136px;
  ${media.lessThan('small')`
    margin-top: -100px;
    padding-top: 100px;
   `}
`
const Image = styled(Img)`
  width: 100vw;
`
